/**
 * @deprecated
 * @see url property of result instead
 * @param result
 * @returns {string|*}
 */
export function urlFromPrismic(result) {
  if (result.url) {
    return result.url;
  }

  let url = result.uid;

  if (result?.data?.sub_category?.uid) {
    url = `${result?.data?.sub_category?.uid}/${url}`;
  }

  if (result?.data?.main_category?.uid) {
    url = `${result?.data?.main_category?.uid}/${url}`;
  }

  return '/' + url;
}

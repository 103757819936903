<template>
  <div>
    <template v-for="(sliceToRender, index) in slicesToRender">
      <ErrorBoundary v-if="index < 2" :key="sliceToRender.bindings.key">
        <component
          :is="sliceToRender.component"
          v-bind="sliceToRender.bindings.props"
          :key="sliceToRender.bindings.key"
        />
      </ErrorBoundary>
      <LazyHydrate v-else :key="sliceToRender.bindings.key" when-visible>
        <ErrorBoundary>
          <component
            :is="sliceToRender.component"
            v-if="index >= 2"
            v-bind="sliceToRender.bindings.props"
          />
        </ErrorBoundary>
      </LazyHydrate>
    </template>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import map from '../../slices/map';
import { Logger } from '~/helpers/logger';
import ProductBlock from '~/slices/ProductBlock/index.vue';
import ErrorBoundary from '~/components/General/ErrorBoundary';

export default {
  name: 'FastSliceZone',
  components: {
    ErrorBoundary,
    ProductBlock,
    LazyHydrate
  },
  props: {
    slices: {
      type: Array,
      required: true
    },
    components: {
      type: Object,
      default: undefined,
      required: false
    },
    resolver: {
      type: Function,
      default: undefined,
      required: false
    },
    context: {
      type: null,
      default: undefined,
      required: false
    },
    defaultComponent: {
      type: Object,
      default: undefined,
      required: false
    },
    wrapper: {
      type: [String, Object, Function],
      default: 'div',
      required: false
    }
  },
  data () {
    return {
    };
  },
  computed: {
    slicesToRender() {
      if (!this.slices) {
        return null;
      }

      return this.slices.map((slice, index) => {
        const key = 'id' in slice && slice.id ? slice.id : ''.concat(`${index}`, '-')?.concat(JSON.stringify(slice));
        const bindings = {
          key,
          props: {
            slice,
            index,
            context: this.context,
            slices: this.slices
          }
        };

        try {
          let component;

          if (typeof slice?.slice_type === 'string' && map[slice.slice_type]) {
            const name = map[slice.slice_type];

            if (name === 'ProductBlock') {
              /**
               * Workaround for apparent bug/deficiency where the ProductBlock was not hydrated with data from server when
               * it was being loaded asynchronously. If many more blocks have to be loaded like this, it might be necessary
               * to evaluate a more sophisticated approach.
               */
              component = ProductBlock;
            } else {
              component = () => import((`@/slices/${name}/index.vue`));
            }
          } else if (this.components) {
            component = this.components && slice.slice_type in this.components ? this.components?.[slice.slice_type] : this.defaultComponent;
          } else {
            Logger.warn(`Could not find slice with type of ${slice.slice_type}. Did you register the component in slices/map.js?`);
          }

          if (this.resolver) {
            const resolvedComponent = this.resolver({
              slice,
              sliceName: slice.slice_type,
              i: index
            });

            if (resolvedComponent) {
              component = resolvedComponent;
            }
          }

          return {
            component,
            bindings
          };
        } catch (e) {
          Logger.error('Error while rendering', slice, 'at index', index);
          Logger.error(e);
          return {
            component: () => import('@/slices/TextContent/index.vue'),
            bindings
          };
        }
      });
    }
  }
};

</script>

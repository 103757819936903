<template>
  <div class="page">
    <CmsBreadcrumbs
      :current="content"
      :category="category"
      :sub-category="sub_category"
      :has-hero="hasHero"
      :is-wide="isWide"
    />
    <div v-if="loading">
      <SkeletonLoader height="min(500px, 50vh)" width="100%" />
    </div>
    <div v-else-if="error">
      I'm sorry, we could not load this content.
    </div>
    <template v-else-if="content && content.data && content.data.slices">
      <FastSliceZone
        :slices="content.data.slices"
      />
    </template>
  </div>
</template>

<script>
import { onSSR } from '@vue-storefront/core';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { useRoute } from '@nuxtjs/composition-api';
import useCms from '@/prismic/composables/useCmsContent.js';
import { Logger } from '@/helpers/logger/index.ts';
import SkeletonLoader from '@/components/SkeletonLoader';
import { urlFromPrismic } from '~/helpers/getUrl';
import { HOME_PAGE_PATH } from '@/prismic/const';
import FastSliceZone from '~/components/App/FastSliceZone.vue';

function simplifyUrl(params) {
  if (params.category && params.subcategory && params.slug) {
    return params;
  }

  if (params.category && params.subcategory) {
    return {
      category: params.category,
      slug: params.subcategory
    };
  }

  return {
    slug: params.category
  };
}

export default {
  name: 'CmsPage',
  components: {
    CmsBreadcrumbs: () => import('@/components/Page/CmsBreadcrumbs'),
    SkeletonLoader,
    FastSliceZone
  },
  setup() {
    const { addTags } = useCache();

    onSSR(() => {
      const { value: route } = useRoute();
      const key = route?.params?.slug || route?.meta?.overrideSlug;
      Logger.info('Route Info', route);

      const tagArray = [
        { prefix: CacheTagPrefix.View, value: 'page' }
      ];

      if (key) {
        tagArray.push({ prefix: 'PRIS:', value: key });
      }

      Logger.info('Adding Tag', tagArray);

      addTags(tagArray);
    });
  },
  async asyncData ({ $prismic, route, error, redirect }) {
    const { queryOne } = useCms($prismic);

    const { slug } = simplifyUrl(route.params);

    const key = slug || route.meta?.[0]?.overrideSlug;

    Logger.info('Search Prismic for ', key);

    const { content, loading, error: prismicError } = await queryOne({
      type: 'page',
      slug: key,
    });

    const subCategory = content?.value?.data?.sub_category;
    let targetUrl = urlFromPrismic(content?.value);

    if (!content.value || !content.value?.data) {
      return error({ statusCode: 404, message: 'Failed to load this page.' });
    }

    if (route.path?.trim() === HOME_PAGE_PATH) {
      targetUrl = '/'; // make sure we don't show the home page path
    }

    if (targetUrl?.trim() !== route.path?.trim() && !process.client && targetUrl?.trim() !== HOME_PAGE_PATH) {
      Logger.info(`Current: ${route.path}, New: ${targetUrl}}`);
      redirect(301, targetUrl);
      return {};
    }

    const mainCategory = {
      ...content?.value?.data?.main_category,
      ...content?.value?.data?.main_category?.data
    };

    return {
      content: content?.value,
      loading: loading?.value,
      error: prismicError?.value,
      title: `${String(content?.value?.data?.title)}`,
      category: mainCategory,
      sub_category: subCategory
    };
  },
  head() {
    return {
      title: this.title,
      meta: [
        { hid: 'name', itemprop: 'name', content: this.title },
        { hid: 'og:title', name: 'og:title', property: 'og:title', content: this.title },
        {
          hid: 'description',
          name: 'description',
          content: this.$prismic.asText(this.content?.data?.summary)
        }
      ]
    };
  },
  computed: {
    hasHero() {
      return this.content?.data?.slices?.[0]?.slice_type === 'hero_banner';
    },
    isWide() {
      return this.content?.data?.slices?.[0]?.slice_type === 'product_block';
    }
  }
};
</script>

<style scoped lang="scss">
</style>
